import $ from "jquery";
import React, { lazy, Suspense, useEffect, useLayoutEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { EventName } from "api/models";
import { AuthTemplate } from "components/common/auth/AuthTemplate";
import { AuthRedirect } from 'components/common/auth/AuthRedirect';
import { useAppDispatch } from "./hooks";
import { setLoading, setUnloading, addError, logError, trackPageView } from "features/global/globalSlice";
import { Constants } from "utils/constants";
import { LicenseManager } from "ag-grid-enterprise";

initializeIcons();
LicenseManager.setLicenseKey(Constants.AGKey);

const App: React.FC = () => {
    const lc = useLocation();
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        $(document)
            .ajaxStart(() => {
                dispatch(setLoading());
            })
            .ajaxComplete(() => {
                dispatch(setUnloading())
            })
            .ajaxError((
                event: JQuery.TriggeredEvent<Document, undefined, Document, Document>,
                jqXHR: JQuery.jqXHR,
                ajaxSettings: JQuery.AjaxSettings,
                thrownError: string
            ) => {
                // Let catch run first
                setTimeout(() => {
                    if ((jqXHR as any).handled) return;

                    const accUrl = "Access URL: <br />" + ajaxSettings?.url + "<br /><br />";
                    const defaultError: string = "Something went wrong.";
                    let error = jqXHR?.responseJSON?.error?.message || thrownError; //|| defaultError;
                    if (error === null || error === "" || error === undefined) {
                        return;
                    }
                    error = accUrl + (error || defaultError);
                    if (jqXHR?.responseJSON?.error?.type === 'NoDatabaseException') {
                        error =
                            '<div>You are not connected to the database.</div>' +
                            '<div class="error-link-wrap mt-3 fw-n">Please screenshot this message, including the page URL, and send it to <a href="mailto:help@wildfire-defense.com">help@wildfire-defense.com</a>.</div>' +
                            '<div class="mt-3 fw-n">Someone will contact you shortly.</div>';
                    }
                    const correlationId = jqXHR?.responseJSON?.error?.correlationId || "";
                    dispatch(addError({ error, correlationId }));
                    dispatch(logError(`${error}\r\n${jqXHR.responseText}`));
                }, 10);
            });
    }, [dispatch]);

    useEffect(() => {
        dispatch(trackPageView({
            name: "Page View",
            url: lc.pathname,
            timestamp: new Date().toDateString()
        }));
    }, [lc, dispatch]);

    const PHAHome = lazy(() => import('../features/pha/PHAHome'));
    const SitMap = lazy(() => import('../features/sitMap/sitMap'));
    const FireMap = lazy(() => import('../features/sitMap/fireMap'));
    const ProtectLandingPage = lazy(() => import('../features/plp/landingPage'));
    const FirePage = lazy(() => import('../features/fde/firePage'));
    const FireOverview = lazy(() => import('../features/fireOverview/fireOverview'));
    const UserManagement = lazy(() => import('../features/admin/userManagement'));
    const SmokeCheck = lazy(() => import('../features/smokeCheck/smokeCheck'));
    const Notifications = lazy(() => import('../features/notifications/notifications'));
    const PropertySearch = lazy(() => import('../features/propertySearch/propertySearch'));
    const ResourceStatus = lazy(() => import('../features/resourceStatus/resourceStatus'));
    const MonitoringHeadquarters = lazy(() => import('../features/monitoringHeadquarters/monitoringHeadquarters'));
    const WorkflowManagement = lazy(() => import('../features/workflowManagement/workflowManagement'));
    const ComingSoon = lazy(() => import('../features/fireOverview/comingSoon'));
    const ProgramUse = lazy(() => import('../features/programUse/programUse'));
    const HistoricFires = lazy(() => import('../features/historicFires/landingPage'));
    const ResourceScheduling = lazy(() => import('../features/resourceScheduling/resourceScheduling'));
    const ClientSubscription = lazy(() => import('../features/clientSubscription/clientSubscription'));
    const ApiManagement = lazy(() => import('../features/apiManagement/apiManagement'));
    const WorkZones = lazy(() => import('../features/workZones/workZones'));
    const MyProfile = lazy(() => import('../features/clientProfile/myProfile'));
    const PropertyProfile = lazy(() => import('../features/propertyProfile/propertyProfile'));

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route path="/pha">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <PHAHome />
                        </AuthTemplate>
                    </Route>
                    <Route path="/sitmap">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal, Constants.Site.External]}
                        >
                            <SitMap />
                        </AuthTemplate>
                    </Route>
                    <Route path="/firemap/:fireId">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal, Constants.Site.External]}
                        >
                            <FireMap />
                        </AuthTemplate>
                    </Route>
                    <Route path="/plp">
                        <Redirect to="/sitmap" />
                    </Route>
                    <Route path="/addfire">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <FirePage />
                        </AuthTemplate>
                    </Route>
                    <Route path="/updatefire/:fireId">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <FirePage />
                        </AuthTemplate>
                    </Route>
                    <Route path="/fireoverview/:fireId/:clientId">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal, Constants.Site.External]}
                        >
                            <FireOverview />
                        </AuthTemplate>
                    </Route>
                    <Route path="/admin/users">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.write}
                            sites={[Constants.Site.Internal]}
                        >
                            <UserManagement />
                        </AuthTemplate>
                    </Route>
                    <Route path="/smokecheck/:fireId">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <SmokeCheck />
                        </AuthTemplate>
                    </Route>
                    <Route path="/workzones/:fireId">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <WorkZones />
                        </AuthTemplate>
                    </Route>
                    <Route path="/notifications/:fireId">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <Notifications />
                        </AuthTemplate>
                    </Route>
                    <Route path="/propertysearch">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                        >
                            <PropertySearch />
                        </AuthTemplate>
                    </Route>
                    <Route path="/resourcestatus">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <ResourceStatus />
                        </AuthTemplate>
                    </Route>
                    <Route path="/monitoringheadquarters">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <MonitoringHeadquarters />
                        </AuthTemplate>
                    </Route>
                    <Route path="/workflowmanagement">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <WorkflowManagement />
                        </AuthTemplate>
                    </Route>
                    <Route path="/comingsoon">
                        <AuthTemplate
                            internalOnly={false}
                        >
                            <ComingSoon />
                        </AuthTemplate>
                    </Route>
                    <Route path="/programUse">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                        >
                            <ProgramUse />
                        </AuthTemplate>
                    </Route>
                    <Route path="/historicFires">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                        >
                            <HistoricFires />
                        </AuthTemplate>
                    </Route>
                    <Route path="/resourceScheduling">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal]}
                        >
                            <ResourceScheduling />
                        </AuthTemplate>
                    </Route>
                    <Route path="/clientSubscription">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.write}
                            sites={[Constants.Site.Internal]}
                        >
                            <ClientSubscription />
                        </AuthTemplate>
                    </Route>
                    <Route path="/profile">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal, Constants.Site.External]}
                        >
                            <MyProfile />
                        </AuthTemplate>
                    </Route>
                    <Route path="/apiManagement">
                        <AuthTemplate
                            internalOnly={true}
                            roles={Constants.AppRoles.write}
                            sites={[Constants.Site.Internal]}
                        >
                            <ApiManagement />
                        </AuthTemplate>
                    </Route>
                    <Route path="/property/:pid">
                        <AuthTemplate
                            internalOnly={false}
                            roles={Constants.AppRoles.allRead}
                            sites={[Constants.Site.Internal, Constants.Site.External]}
                        >
                            <PropertyProfile />
                        </AuthTemplate>
                    </Route>
                    <Route path="/">
                        <AuthTemplate
                            internalOnly={false}
                        >
                            <AuthRedirect />
                        </AuthTemplate>
                    </Route>
                </Switch>
            </Suspense>
        </>
    );
}

export default App;